import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/layout/main/main.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { MenuItemComponent } from './components/layout/menu/menu-item/menu-item.component';
import { DlTableModule } from './modules/dl-table/dl-table.module';
import { DlFormsModule } from './dl-forms/dl-forms.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './auth/interceptors/jwt.interceptor';
import { AbstractTableComponent } from './modules/crud/components/abstract-table/abstract-table.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from './errors/error-handler';
import { UnauthorisedInterceptor } from './auth/interceptors/unauthorised.interceptor';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingInterceptor } from './auth/interceptors/loading.interceptor';
import { MessagePopupModule } from './messages/messages-popup/message-popup.module';

import localeHr from '@angular/common/locales/hr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeHr);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FooterComponent,
    TopbarComponent,
    MenuComponent,
    MenuItemComponent,
    AbstractTableComponent,
    ProgressSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DlTableModule,
    DlFormsModule,
    HttpClientModule,
    ToastModule,
    ProgressSpinnerModule,
    MessagePopupModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorisedInterceptor, multi: true },

    MessageService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: LOCALE_ID, useValue: 'hr' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
