import { RoleEnum } from './../auth/enums/role.enum';
import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuSource = new Subject<string>();
  private resetSource = new Subject<void>();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  overlayMenuActive = false;
  menuClick = false;
  search = false;
  searchClick = false;
  userMenuClick = false;
  topbarUserMenuActive = false;
  notificationMenuClick = false;
  resetMenu = false;
  menuHoverActive = false;
  staticMenuMobileActive = false;
  menuMode = 'slim';
  staticMenuDesktopInactive = false;

  constructor(private deviceService: DeviceService, private authService: AuthService) {}
  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next();
  }

  onTopbarUserMenuButtonClick(event: Event) {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isSlim() {
    return true;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  }

  onMenuClick() {
    this.menuClick = true;
    this.resetMenu = false;
  }

  hideAll() {
    this.userMenuClick = false;
    this.notificationMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: Event) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.deviceService.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  getMenuItems(): MenuItem[] & { permissions?: Array<string> }[] {
    return [
      {
        label: 'Nadzorna ploča',
        icon: 'pi pi-home',
        routerLink: ['/'],
        permissions: [RoleEnum.Admin],
      },
      { separator: true },
      {
        label: 'Škole',
        icon: 'pi pi-fw pi-building',
        routerLink: ['/blocks'],

        permissions: [RoleEnum.ZupanijskiPovjerenik, RoleEnum.Povjerenik],
        items: [
          {
            label: 'Sve škole',
            icon: 'pi pi-fw pi-table',
            routerLink: ['/schools'],
          },
          {
            label: 'Dodaj školu',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/schools/create'],
            permissions: [RoleEnum.Admin],
            target: '_blank',
          },
        ],
      },
      { separator: true },
      {
        label: 'Članovi',
        icon: 'pi pi-fw pi pi-users',
        routerLink: ['/blocks'],
        permissions: [RoleEnum.ZupanijskiPovjerenik, RoleEnum.Povjerenik],
        items: [
          {
            label: 'Svi članovi',
            icon: 'pi pi-fw pi-table',
            routerLink: ['/members'],
            target: '_blank',
          },
          {
            label: 'Dodaj člana',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/members/create'],
            target: '_blank',
          },
        ],
      },
      { separator: true },
      {
        label: 'Adrese',
        icon: 'pi pi-fw pi pi-map',
        permissions: [RoleEnum.Admin],
        items: [
          {
            label: 'Sve adrese',
            icon: 'pi pi-fw pi pi-table',
            routerLink: ['/address'],
            target: '_blank',
          },
          {
            label: 'Dodaj adresu',
            icon: 'pi pi-fw pi pi-user-plus',
            routerLink: ['/address/create'],
            target: '_blank',
          },
          {
            label: 'Gradovi',
            icon: 'pi pi-fw pi pi-map',
            items: [
              {
                label: 'Svi gradovi',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/cities'],
                target: '_blank',
              },
              {
                label: 'Dodaj Grad',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/cities/create'],
                target: '_blank',
              },
            ],
          },
          {
            label: 'Općine',
            icon: 'pi pi-fw pi pi-map',
            items: [
              {
                label: 'Sve općine',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/counties'],
                target: '_blank',
              },
              {
                label: 'Dodaj općinu',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/counties/create'],
                target: '_blank',
              },
            ],
          },
          {
            label: 'Županije',
            icon: 'pi pi-fw pi pi-map',
            items: [
              {
                label: 'Sve županije',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/regions'],
                target: '_blank',
              },
              {
                label: 'Dodaj županiju',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/regions/create'],
                target: '_blank',
              },
            ],
          },
        ],
      },
      { separator: true },
      {
        label: 'Izvješća',
        icon: 'pi pi-fw pi pi-file',
        permissions: [RoleEnum.ZupanijskiPovjerenik, RoleEnum.Povjerenik],
        items: [
          {
            label: 'Financijska',
            items: [
              {
                label: 'Kumulativna',
                target: '_blank',
                permissions: [RoleEnum.Admin],
                items: [
                  {
                    label: 'Sva izvješća',
                    icon: 'pi pi-fw pi pi-table',
                    routerLink: ['/financial-report/cumulative'],
                    target: '_blank',
                  },
                  {
                    label: 'Dodaj Izvješće',
                    icon: 'pi pi-fw pi pi-user-plus',
                    routerLink: ['/financial-report/cumulative/create'],
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Pojedinačna',
                target: '_blank',
                items: [
                  {
                    label: 'Sva izvješća',
                    icon: 'pi pi-fw pi pi-table',
                    routerLink: ['/financial-report'],
                    target: '_blank',
                  },
                  {
                    label: 'Dodaj Izvješće',
                    icon: 'pi pi-fw pi pi-user-plus',
                    routerLink: ['/financial-report/create'],
                    target: '_blank',
                  },
                ],
              },
            ],
          },
          {
            label: 'Joppd',
            routerLink: ['/blocks'],
            permissions: [RoleEnum.ZupanijskiPovjerenik, RoleEnum.Povjerenik],
            items: [
              {
                label: 'Sva Joppd izvješća',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/joppd-report'],
                target: '_blank',
              },
              {
                label: 'Dodaj Joppd izvješće',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/joppd-report/create'],
                target: '_blank',
              },
            ],
          },
        ],
      },
      { separator: true },

      {
        label: 'Upitnici',
        icon: 'pi pi-fw pi pi-file',
        permissions: [RoleEnum.ZupanijskiPovjerenik, RoleEnum.Povjerenik],
        items: [
          {
            label: 'Teme izjašnjavanja',
            target: '_blank',
            permissions: [RoleEnum.Admin],
            items: [
              {
                label: 'Sve teme',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/statement'],
                target: '_blank',
              },
              {
                label: 'Dodaj temu',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/statement/create'],
                target: '_blank',
              },
            ],
          },
          {
            label: 'Izjašnjavanja',
            target: '_blank',
            permissions: [],
            items: [
              {
                label: 'Sva izjašnjavanja',
                icon: 'pi pi-fw pi pi-table',
                routerLink: ['/organization-statement'],
                target: '_blank',
              },
              {
                label: 'Upiši rezultate',
                icon: 'pi pi-fw pi pi-user-plus',
                routerLink: ['/organization-statement/create'],
                target: '_blank',
              },
            ],
          },
        ],
      },
      { separator: true },
      {
        label: 'Stanje članstva',
        icon: 'pi pi-fw pi pi-file',
        permissions: [RoleEnum.Admin],

        items: [
          {
            label: 'Škole',
            icon: 'pi pi-fw pi-table',
            routerLink: ['membership/schools'],
            target: '_blank',
          },
          {
            label: 'Članovi',
            icon: 'pi pi-fw pi-table',
            routerLink: ['membership/members'],
            target: '_blank',
          },
        ],
      },
      { separator: true },

      {
        label: 'Radna mjesta',
        icon: 'pi pi-fw pi-briefcase',
        routerLink: ['/working-place'],
        permissions: [RoleEnum.Admin],
        items: [
          {
            label: 'Sva radna mjesta',
            icon: 'pi pi-fw pi pi-table',
            routerLink: ['/working-place'],
          },
          {
            label: 'Dodaj radno mjesto',
            icon: 'pi pi-fw pi pi-user-plus',
            routerLink: ['/working-place/create'],
            target: '_blank',
          },
        ],
      },
      // { separator: true },
      // {
      //   label: 'Obavijesti',
      //   icon: 'pi pi-fw pi-bell',
      //   routerLink: ['/messages'],
      //   permissions: [RoleEnum.Admin],
      //   items: [
      //     {
      //       label: 'Sve poruke',
      //       icon: 'pi pi-fw pi pi-table',
      //       routerLink: ['/messages'],
      //     },
      //     {
      //       label: 'Nova obavijest',
      //       icon: 'pi pi-fw pi pi-plus',
      //       routerLink: ['/messages/create'],
      //       target: '_blank',
      //     },
      //   ],
      // },
      { separator: true },
      {
        label: 'Odjava',
        icon: 'pi pi-fw pi pi-sign-out',
        command: () => {
          this.authService.logout();
        },
      },
    ];
  }
}
