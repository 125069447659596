import { ListResponse } from '../../modules/crud/models/list-response.model';
import { interval, mergeMap, Subscription } from 'rxjs';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Message, MessageDTO } from '../models/message.model';
import { MessageService } from '../message.service';
import { SearchCriteria } from 'src/app/modules/crud/models/search-criteria.model';
import { SortOrder } from 'src/app/modules/crud/enums/sort-order.enum';
import { MessageListResponse } from '../models/message-list-repsponse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messages-popup',
  templateUrl: './messages-popup.component.html',
  styleUrls: ['./messages-popup.component.scss'],
})
export class MessagesPopupComponent implements OnInit {
  menuOpen: boolean = false;

  messages: Array<Message> = [];
  unreadCount: number = 0;
  subscription: Subscription = new Subscription();

  @Output()
  toggleMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private messageService: MessageService, private router: Router) {}

  ngOnInit() {
    const searchCriteria = new SearchCriteria();
    searchCriteria.sortBy = {
      column: 'created',
      direction: SortOrder.DESC,
    };

    const subscription = this.messageService
      .getMyAll(searchCriteria)
      .subscribe((response: ListResponse<Message, MessageDTO>) => {
        this.messages = response.records.slice(0, 5);
        this.unreadCount = (response as any).unreadCount;
      });

    this.subscription.add(subscription);

    const intervalSubscription = interval(60000)
      .pipe(mergeMap(() => this.messageService.getMyAll(searchCriteria)))
      .subscribe((response: ListResponse<Message, MessageDTO>) => {
        this.messages = response.records.slice(0, 5);
        this.unreadCount = (response as any).unreadCount;
      });

    this.subscription.add(intervalSubscription);
  }

  toggleNotificationMenu(event: Event) {
    event.preventDefault();
    this.menuOpen = !this.menuOpen;

    if (this.menuOpen && this.unreadCount > 0) {
      this.markAsRead();
    }

    this.toggleMenu.emit(this.menuOpen);
  }

  markAsRead() {
    const unreadMessagesIds: Array<string> = this.messages
      .filter((message) => !message.userMessage || message.userMessage.read !== true)
      .map((message) => message.id as string);

    const subscription = this.messageService
      .markAsRead(unreadMessagesIds)
      .subscribe((response: MessageListResponse) => {
        this.messages = response.records;
        this.unreadCount = (response as any).unreadCount;
      });
    this.subscription.add(subscription);
  }

  onMessageClick(event: any, id: string | undefined) {
    event.preventDefault();
    this.router.navigateByUrl('/messages/view/' + id);
  }
}
