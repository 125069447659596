import { PermissionsService } from 'src/app/auth/permissions.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  model: any[] = [];
  wasInside = false;
  closeAllMenus: EventEmitter<void> = new EventEmitter();

  constructor(private menuService: MenuService, private permisionsService: PermissionsService) {}

  ngOnInit() {
    this.model = this.menuService.getMenuItems();
  }

  handleMenuClick() {
    this.menuService.onMenuClick();
  }

  checkPermissions(permissions: Array<string> = []) {
    if (permissions.length === 0) {
      return true;
    }

    return this.permisionsService.checkPermission(permissions);
  }
}
