import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  isDesktop() {
    return window.innerWidth > 1091;
  }

  isMobile() {
    return window.innerWidth <= 1091;
  }
}
