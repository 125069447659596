<div class="layout-sidebar" (click)="handleMenuClick()">
  <a [routerLink]="['/']" class="logo">
    <img id="app-logo" class="logo-image" src="assets/images/logo.jpg" alt="preporod" />
  </a>

  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index">
        <ng-container *ngIf="checkPermissions(item.permissions) && !item.separator">
          <li app-menu-item [item]="item" [index]="i" [root]="true"></li>
          <li class="menu-separator"></li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
