import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  items: MenuItem[] = [];
  subscription: Subscription = new Subscription();

  notificationDropdownOpen: boolean = false;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private menuService: MenuService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscription = this.breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
    });
  }

  toggleNotificationMenu(event: boolean) {
    this.notificationDropdownOpen = event;
  }

  isUserMenuActive() {
    return this.menuService.topbarUserMenuActive;
  }

  toggleUserMenu(event: Event) {
    this.menuService.onTopbarUserMenuButtonClick(event);
  }

  onMenuButtonClick(event: Event) {
    this.menuService.onMenuButtonClick(event);
  }

  getUserName() {
    return this.authService.getUser().firstName;
  }

  getUserLastName() {
    return this.authService.getUser().lastName;
  }

  logout(event: Event) {
    event.preventDefault();
    this.authService.logout();
  }
}
