<a href="#" class="toggle" tabindex="0" (click)="toggleNotificationMenu($event)">
  <i class="pi pi-bell"></i>
  <span *ngIf="unreadCount > 0" class="topbar-badge">{{ unreadCount }}</span>
</a>
<ul class="dropdown notifications-menu fade-in-up">
  <li role="menuitem" *ngFor="let message of messages; let i = index">
    <a href="#" [attr.tabindex]="i" (click)="onMessageClick($event, message?.id)">
      <i class="pi pi-envelope"></i>
      <div class="notification-item">
        <div class="notification-summary">{{ message.title }}</div>
        <div class="notification-detail">{{ message.body }}</div>
      </div>
    </a>
  </li>
  <li style="background: #efefef; display: flex; justify-content: center">
    <a style="width: 100%; display: block; text-align: center" href="" [routerLink]="['/messages']">Sve poruke</a>
  </li>
</ul>
