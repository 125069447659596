import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  nonLoadingUrls = ['suggestions', 'messages/me/list'];
  constructor(private spinnerService: ProgressSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const nonloading = this.nonLoadingUrls.find((val) => request.url.includes(val));

    if (!nonloading) {
      this.spinnerService.show();
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.spinnerService.hide();
      })
    );
  }
}
