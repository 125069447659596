import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit {
  constructor(public spinnerService: ProgressSpinnerService, private cdr: ChangeDetectorRef) {}
  isVisible = false;
  ngOnInit(): void {
    this.spinnerService.visibilityChanged().subscribe((visibility: boolean) => {
      this.isVisible = visibility;
      this.cdr.detectChanges();
    });
  }
}
