/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/services/device.service';
import { MenuService } from 'src/app/services/menu.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { filter } from 'rxjs/operators';
import { PermissionsService } from 'src/app/auth/permissions.service';
@Component({
  selector: '[app-menu-item]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  host: {
    '[class.layout-root-menuitem]': 'root',
    '[class.active-menuitem]': '(active)',
  },
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      state(
        'slimVisibleAnimated',
        style({
          opacity: 1,
          transform: 'none',
        })
      ),
      state(
        'slimHiddenAnimated',
        style({
          opacity: 0,
          transform: 'translateX(20px)',
        })
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
      transition('slimHiddenAnimated => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
    ]),
  ],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  @Input() index = 0;

  @Input() root = false;

  @Input() parentKey = '';

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key = '';

  slimClick = false;

  constructor(
    public router: Router,
    private menuService: MenuService,
    private deviceService: DeviceService,
    private permissionService: PermissionsService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe((key) => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      this.active = false;
    });
  }

  ngOnInit() {
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
  }

  updateActiveStateFromRoute() {
    this.active = this.router.isActive(this.item.routerLink[0], !this.item.items && !this.item.preventExact);
  }

  itemClick(event: Event) {
    this.slimClick = true;

    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.menuService.menuHoverActive = !this.menuService.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      if (this.deviceService.isMobile()) {
        this.menuService.staticMenuMobileActive = false;
      }

      // reset horizontal menu
      this.menuService.reset();
      this.menuService.menuHoverActive = false;

      this.menuService.unblockBodyScroll();
    }

    this.removeActiveInk(event);
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && this.deviceService.isDesktop()) {
      if (this.menuService.menuHoverActive) {
        this.menuService.onMenuStateChange(this.key);
        this.active = true;
        this.slimClick = false;
        return;
      }
      this.slimClick = true;
    }
  }

  removeActiveInk(event: Event) {
    const currentTarget = event.currentTarget as HTMLElement;
    setTimeout(() => {
      if (currentTarget) {
        const activeInk = currentTarget.querySelector('.p-ink-active');
        if (activeInk) {
          if (activeInk.classList) activeInk.classList.remove('p-ink-active');
          else
            activeInk.className = activeInk.className.replace(
              new RegExp('(^|\\b)' + 'p-ink-active'.split(' ').join('|') + '(\\b|$)', 'gi'),
              ' '
            );
        }
      }
    }, 401);
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }

  checkPermissions(permissions: Array<string> = []) {
    if (permissions.length === 0) {
      return true;
    }

    return this.permissionService.checkPermission(permissions);
  }
}
