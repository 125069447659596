import { Model } from 'ngx-super-model';

export class AbstractCRUDModel<T> extends Model<T> {
  id? = '';
  created: Date | null = null;
  updated: Date | null = null;
  createdBy: string | null = null;
  updatedBy: string | null = null;
  deletedAt?: Date | null = null;

  getId(): string {
    return this.id as string;
  }
}
