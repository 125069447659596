import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesPopupComponent } from './messages-popup.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MessagesPopupComponent],
  imports: [CommonModule, RouterModule],
  exports: [MessagesPopupComponent],
})
export class MessagePopupModule {}
