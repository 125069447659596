import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ThemeConfig } from 'src/app/config/theme.config';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  subscription: Subscription = new Subscription();
  items: MenuItem[] = [];

  colorScheme = ThemeConfig.colorScheme;
  menuTheme = ThemeConfig.menuTheme;

  constructor(private menuService: MenuService) {}

  isMobileLayoutActive() {
    return this.menuService.staticMenuMobileActive;
  }

  onLayoutClick() {
    if (!this.menuService.userMenuClick) {
      this.menuService.topbarUserMenuActive = false;
    }

    if (!this.menuService.menuClick) {
      this.menuService.reset();

      if (this.menuService.overlayMenuActive || this.menuService.staticMenuMobileActive) {
        this.menuService.hideOverlayMenu();
      }

      this.menuService.menuHoverActive = false;
      this.menuService.unblockBodyScroll();
    }
    this.menuService.hideAll();

    // this.searchClick = false;
    // this.configClick = false;
    // this.menuService.userMenuClick = false;
    // this.rightMenuClick = false;
    // this.notificationMenuClick = false;
    // this.menuClick = false;
  }
}
