import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DlTableComponent } from './components/dl-table/dl-table.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DlHeaderCellComponent } from './components/dl-table/dl-header-cell/dl-header-cell.component';
import { DlCellComponent } from './components/dl-table/dl-cell/dl-cell.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DlToolbarComponent } from './components/dl-table/dl-toolbar/dl-toolbar.component';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TagModule } from 'primeng/tag';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ProgressBarModule,
    SliderModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    ToolbarModule,
    ConfirmDialogModule,
    TagModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [DlTableComponent, DlHeaderCellComponent, DlCellComponent, DlToolbarComponent],
  exports: [DlTableComponent],
  providers: [ConfirmationService],
})
export class DlTableModule {}
