import { BlameMetaEnum } from '../enums/blame-meta.enum';
import { MessageChannelEnum } from '../enums/message-channel.enum';

export interface MessageMetaDTO {
  channels: Array<MessageChannelEnum>;
  userId: string;
}

export class MessageMeta {
  channels?: Array<MessageChannelEnum> = [MessageChannelEnum.Message];
  blame?: BlameMetaEnum | null = null;
  userId?: string = '';
}
