import { Model } from 'ngx-super-model';
import { SortOrder } from '../enums/sort-order.enum';
import { FilterByDTO } from '../interfaces/filter.interface';
import { PaginationDTO } from '../interfaces/pagination.interface';
import { SearchCriteriaDTO } from '../interfaces/search-criteria.dto';
import { SortBy } from '../interfaces/sort.interface';

export class SearchCriteria extends Model<SearchCriteriaDTO> implements SearchCriteriaDTO {
  version? = '1';
  searchQuery?: string = undefined;
  filterBy?: FilterByDTO = undefined;
  sortBy?: SortBy = {
    column: 'created',
    direction: SortOrder.DESC,
  };

  pagination: PaginationDTO = {
    page: 1,
    size: 50,
  };
}
