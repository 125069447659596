import { AbstractCRUDModel } from './../../modules/crud/models/abstract-crud.model';

export interface UserDTO {
  id?: string;
  username: string;
  createdAt?: string;
  updatedAt?: string;
  firstName: string;
  lastName?: string;
  registered?: boolean;
  active: boolean;
  deleted?: boolean;
  roles: Array<string>;
  subsidiary?: { id: number; name: string };
  permissions: Array<string>;
}

export class User extends AbstractCRUDModel<UserDTO> {
  username = '';
  createdAt = '';
  updatedA = '';
  firstName = '';
  lastName = '';
  registered = false;
  active = false;
  deleted = false;
  roles = [];
  permissions = [];
  subsidiary: { id: number; name: string } | null = null;

  hasRoles(roles: Array<string>) {
    return this.roles.every(function (val) {
      return roles.indexOf(val) !== -1;
    });
  }
}
