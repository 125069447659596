import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/layout/main/main.component';
import { AuthGuard } from './auth/helpers/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'financial-report',
        pathMatch: 'full',
      },
      {
        path: 'members',
        loadChildren: () => import('./members/members.module').then((m) => m.MembersModule),
      },
      {
        path: 'schools',
        loadChildren: () => import('./schools/schools.module').then((m) => m.SchoolsModule),
      },
      {
        path: 'regions',
        loadChildren: () => import('./address/region/region.module').then((m) => m.RegionModule),
      },
      {
        path: 'counties',
        loadChildren: () => import('./address/county/county.module').then((m) => m.CountyModule),
      },
      {
        path: 'cities',
        loadChildren: () => import('./address/city/city.module').then((m) => m.CityModule),
      },
      {
        path: 'address',
        loadChildren: () => import('./address/address.module').then((m) => m.AddressModule),
      },
      {
        path: 'working-place',
        loadChildren: () => import('./working-place/working-place.module').then((m) => m.WorkingPlaceModule),
      },
      {
        path: 'statement',
        loadChildren: () => import('./statement/statement.module').then((m) => m.StatementModule),
      },
      {
        path: 'organization-statement',
        loadChildren: () =>
          import('./organization-statement/organization-statement.module').then((m) => m.OrganizationStatementModule),
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./region-report/region-report.module').then((m) => m.OrganizationRegionReportModule),
      },
      {
        path: 'financial-report/cumulative',
        loadChildren: () =>
          import('./modules/report/financial-report-cumulative/financial-report-cumulative.module').then(
            (m) => m.FinancialReportCumulativeModule
          ),
      },
      {
        path: 'financial-report',
        loadChildren: () =>
          import('./modules/report/financial-report/financial-report.module').then((m) => m.FinancialReportModule),
      },
      {
        path: 'joppd-report',
        loadChildren: () => import('./modules/report/joppd/joppd-report.module').then((m) => m.JoppdReportModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
