import { Injectable, ErrorHandler, Injector, Inject, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserFeedbackService } from '../services/user-feedback.service';
import { ProgressSpinnerService } from '../services/progress-spinner.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    @Inject(Injector) private injector: Injector,
    private ngZone: NgZone,
    private spinnerService: ProgressSpinnerService
  ) {}

  private get userFeedbackService(): UserFeedbackService {
    return this.injector.get(UserFeedbackService);
  }

  handleError(error: any) {
    this.ngZone.run(() => {
      if (error instanceof HttpErrorResponse) {
        this.spinnerService.hide();
        if (error.status == 409) {
          this.userFeedbackService.error('Na žalost dogodila se greška!', error.error.message);
          return;
        }
        this.userFeedbackService.error(
          'Na žalost dogodila se greška!',
          'Pokušajte za nekoliko minuta ili vratite se kasnije.'
        );
        return;
      }
      console.error(error.stack);
      this.spinnerService.hide();
      throw error;
    });
  }
}
