import { AbstractCRUDModel } from './../../modules/crud/models/abstract-crud.model';
import { MessageMeta } from './message-meta.model';
import { MessagePriorityEnum } from '../enums/message-priority.enum';
import { MessageVisibilityEnum } from '../enums/message-visibility.enum';

export interface UserMessageDTO {
  user_id?: string;
  read?: boolean;
  message_id?: string;
}

export interface MessageDTO {
  title: string;
  body?: string;
  priority?: MessagePriorityEnum;
  meta?: MessageMeta;
  visibility: MessageVisibilityEnum;
  userMessage?: UserMessageDTO | null;
}

export class Message extends AbstractCRUDModel<MessageDTO> {
  title: string = '';
  body?: string = '';
  priority?: MessagePriorityEnum = MessagePriorityEnum.Medium;
  meta?: MessageMeta = new MessageMeta();
  visibility: MessageVisibilityEnum = MessageVisibilityEnum.Role;
  userMessage?: UserMessageDTO | null = null;
}
