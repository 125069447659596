import { LookupInterface } from 'src/app/dl-forms/interfaces/lookup-interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractCRUDModel } from './../models/abstract-crud.model';
import { environment } from './../../../../environments/environment';
import { SearchCriteria } from './../models/search-criteria.model';
import { SuggestionResultDTO } from '../interfaces/suggestion-result.dto';
import { ListResponseDTO } from '../interfaces/list-response.interface';
import { ListResponse } from '../models/list-response.model';

@Injectable({
  providedIn: 'root',
})
export class AbstractCRUDService<M extends AbstractCRUDModel<D>, D> implements LookupInterface {
  private apiHost = environment.apiHost;
  public model!: new () => M;
  path = '';

  constructor(protected httpClient: HttpClient) {}

  getApiHost(): string {
    return this.apiHost;
  }

  setApiHost(apiHost: string): void {
    this.apiHost = apiHost;
  }

  getAll(searchCriteria?: SearchCriteria, options?: { headers: HttpHeaders }): Observable<ListResponse<M, D>> {
    const url = this.getApiHost() + this.path + '/list';
    return this.httpClient.post<ListResponseDTO<D>>(url, searchCriteria, options).pipe(
      map((result: ListResponseDTO<D>) => {
        const response = new ListResponse<M, D>().loadModel(result);
        response.records = result.records.map(this.loadModel);
        return response;
      })
    );
  }

  getMyAll(searchCriteria?: SearchCriteria, options?: { headers: HttpHeaders }): Observable<ListResponse<M, D>> {
    const url = this.getApiHost() + this.path + '/me/list';
    return this.httpClient.post<ListResponseDTO<D>>(url, searchCriteria, options).pipe(
      map((result: ListResponseDTO<D>) => {
        const response = new ListResponse<M, D>().loadModel(result);
        response.records = result.records.map(this.loadModel);
        return response;
      })
    );
  }

  getSuggestions(
    searchCriteria?: SearchCriteria,
    options?: { headers: HttpHeaders }
  ): Observable<ListResponseDTO<SuggestionResultDTO>> {
    const url = this.getApiHost() + this.path + '/suggestions';
    return this.httpClient.post<ListResponseDTO<SuggestionResultDTO>>(url, searchCriteria, options);
  }

  getLookupValues(value?: any): Observable<SuggestionResultDTO[]> {
    const searchCriteria = new SearchCriteria();
    searchCriteria.pagination.size = 500;
    searchCriteria.searchQuery = value;
    return this.getSuggestions(searchCriteria).pipe(map((result) => result.records));
  }

  getOne(id: string): Observable<M> {
    const url: string = this.getApiHost() + `${this.path}/${id}`;
    return this.httpClient.get<D>(url).pipe(map(this.loadModel));
  }

  save(model: M, options?: { headers: HttpHeaders }): Observable<M> {
    if (model.id === '') {
      delete model.id;
    }

    if (model.id) {
      return this.update(model, options);
    }

    return this.create(model, options);
  }

  update(model: M, options?: { headers: HttpHeaders }): Observable<M> {
    const url: string = this.getApiHost() + `${this.path}/${model.id}`;
    return this.httpClient.put<D>(url, model, options).pipe(map(this.loadModel));
  }

  create(model: M, options?: { headers: HttpHeaders }): Observable<M> {
    const url: string = this.getApiHost() + `${this.path}`;
    return this.httpClient.post<D>(url, model, options).pipe(map(this.loadModel));
  }

  delete(id: string): Observable<any> {
    const url: string = this.getApiHost() + `${this.path}/${id}`;
    return this.httpClient.delete(url);
  }

  download(searchCriteria?: SearchCriteria, options?: { headers: HttpHeaders }) {
    const url = this.getApiHost() + this.path + '/export/excel';
    return this.httpClient.post(url, searchCriteria, {
      responseType: 'blob',
    });
  }

  loadModel = (item: D) => new this.model().loadModel(item);
}
