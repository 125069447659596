export const TranslationConfig = {
  startsWith: 'Počinje s',
  contains: 'Sadrži',
  notContains: 'Ne sadrži',
  endsWith: 'Završava s',
  equals: 'Jednako',
  notEquals: 'Nije jednako',
  noFilter: 'Bez filtera',
  lt: 'Manje od',
  lte: 'Manje ili jednako',
  gt: 'Veće od',
  gte: 'Veće ili jednako',
  is: 'Je',
  isNot: 'Nije',
  before: 'Prije',
  after: 'Poslije',
  dateIs: 'Datum je',
  dateIsNot: 'Datum nije',
  dateBefore: 'Datum je prije',
  dateAfter: 'Datum je poslije',
  clear: 'Očisti',
  apply: 'Primijeni',
  matchAll: 'Odgovaraj svima',
  matchAny: 'Odgovaraj nekima',
  addRule: 'Dodaj filter',
  removeRule: 'Ukloni pravilo',
  accept: 'Prihvati',
  reject: 'Odustani',
  choose: 'Odaberi',
  upload: 'Učitaj',
  cancel: 'Odustani',
  dayNames: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
  dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
  monthNames: [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac',
  ],
  monthNamesShort: ['Sij', 'Velj', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp', 'Kol', 'Ruj', 'Lis', 'Stu', 'Pro'],
  dateFormat: 'mm/dd/yyyy',
  firstDayOfWeek: 0,
  today: 'Danas',
  weekHeader: 'Tjedan',
  weak: 'Slabo',
  medium: 'Srednje',
  strong: 'Jako',
  passwordPrompt: 'Unesite lozinku',
  emptyMessage: 'Nisu pronađeni rezultati',
  emptyFilterMessage: 'Nisu pronađeni rezultati',
};
