import { Injectable } from '@angular/core';
import { Permissions } from './models/permissions.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissions = new Permissions();

  canActivate() {
    return true;
  }

  setPermissions(permissions: Permissions) {
    this.permissions = permissions;
  }

  getPermissions() {
    return this.permissions;
  }

  checkPermission(permissions: Array<string>) {
    return this.permissions.checkPermission(permissions);
  }
}
