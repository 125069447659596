<div
  class="layout-wrapper layout-slim"
  [class]="colorScheme === 'light' ? menuTheme : ''"
  [ngClass]="{ 'layout-mobile-active': isMobileLayoutActive() }"
  [attr.data-theme]="colorScheme"
  (click)="onLayoutClick()"
>
  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>
    <div class="layout-content">
      <ng-template ngFor let-item let-last="last" [ngForOf]="items">
        <span class="viewname" style="text-transform: uppercase">{{ item.label }}</span>
      </ng-template>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>

  <!-- <app-config></app-config>

  <app-search></app-search>

  <app-rightmenu></app-rightmenu> -->
</div>
