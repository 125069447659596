<div class="layout-topbar">
  <div class="topbar-left">
    <a tabindex="0" class="menu-button" (click)="onMenuButtonClick($event)">
      <i class="pi pi-chevron-left"></i>
    </a>
    <img
      id="logo-horizontal"
      class="horizontal-logo"
      [routerLink]="['/']"
      src="assets/layout/images/logo-white.svg"
      alt="diamond-layout"
    />
    <span class="topbar-separator"></span>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <span class="viewname" style="text-transform: uppercase">{{ item.label }}</span>
    </ng-template>
    <img id="logo-mobile" class="mobile-logo" src="assets/images/logo.jpg" alt="diamond-layout" />
  </div>

  <app-menu></app-menu>

  <div class="layout-mask modal-in"></div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <li class="notifications-item" [ngClass]="{ 'active-menuitem': notificationDropdownOpen }">
        <app-messages-popup (toggleMenu)="toggleNotificationMenu($event)"></app-messages-popup>
      </li>

      <li
        class="profile-item"
        [ngClass]="{
          'active-menuitem': isUserMenuActive()
        }"
      >
        <a href="#" class="toggle" (click)="toggleUserMenu($event)">
          <span class="profile-name">{{ getUserName() }} {{ getUserLastName() }}</span>
        </a>
        <ul class="dropdown profile-menu fade-in-up">
          <!-- <li>
            <a href="#">
              <i class="pi pi-user"></i>
              <span>Profil</span>
            </a>
          </li>-->
          <li>
            <a href="#" [routerLink]="['/messages']">
              <i class="pi pi-bell"></i>
              <span>Obavijesti</span>
            </a>
          </li>
          <li>
            <a href="#" (click)="logout($event)">
              <i class="pi pi-calendar"></i>
              <span>Odjava</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
