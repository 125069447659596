import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  constructor(private messageService: MessageService) {}
  error(title: string, description: string) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: title,
      detail: description,
    });
  }

  success(title: string, description: string) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: title,
      detail: description,
    });
  }

  clear() {
    this.messageService.clear();
  }
}
