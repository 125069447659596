import { UserDTO, User } from './../user/models/user';
import { AuthError } from './errors/auth-error';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractCRUDService } from '../modules/crud/services/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractCRUDService<User, UserDTO> {
  login(username: string, password: string, rememberMe: boolean): Observable<User> {
    return this.httpClient
      .post(this.getApiHost() + '/auth/signin', {
        username,
        password,
        rememberMe,
      })
      .pipe(
        map((response: any) => {
          if (!response) {
            throw new AuthError('Korisnik ne postoji ili ste unijeli pogrešnu lozinku.');
          }
          localStorage.setItem('user', JSON.stringify(response));
          return new User().loadModel(response.user);
        })
      );
  }

  logout() {
    localStorage.removeItem('user');
    location.reload();
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (!user) {
      return new User();
    }
    return new User().loadModel(JSON.parse(user).user.user_info);
  }

  isLoggedIn() {
    return localStorage.getItem('user');
  }

  getUserToken() {
    const user = localStorage.getItem('user');
    if (!user) {
      return;
    }
    return JSON.parse(user).accessToken;
  }

  activateAccount(token: string, password: string) {
    return this.httpClient.post(this.getApiHost() + '/auth/activate-account', {
      token,
      password,
    });
  }

  resetPassword(token: string, password: string) {
    return this.httpClient.post(this.getApiHost() + '/auth/reset-password', {
      token,
      password,
    });
  }

  sendPasswordResetToken(email: string) {
    return this.httpClient.post(this.getApiHost() + '/auth/send-password-reset-token', {
      username: email,
    });
  }

  validateToken(token: string) {
    return this.httpClient.post(this.getApiHost() + '/auth/verify-token', {
      token: token,
    });
  }

  validateResetToken(token: string) {
    return this.httpClient.post(this.getApiHost() + '/auth/verify-password-reset-token', {
      token: token,
    });
  }
}
