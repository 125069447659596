import { Model } from 'ngx-super-model';
import { ListResponseDTO, PageDetails } from '../interfaces/list-response.interface';
import { AbstractCRUDModel } from './abstract-crud.model';

export class ListResponse<M extends AbstractCRUDModel<D>, D> extends Model<ListResponseDTO<D>> {
  pageDetails: PageDetails = {
    isFirst: false,
    isLast: false,
    page: 0,
    size: 0,
    totalPages: 0,
    totalResults: 0,
  };
  records: Array<M> = [];
}
