import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributesDirective } from './directives/attributes.directive';
import { PhoneNumberComponent } from './ui/controls/phone-number/phone-number.component';
import { ValidationMessageComponent } from './ui/validation-message/validation-message.component';
import { InfoFieldComponent } from './ui/controls/info-field/info-field.component';
import { CheckboxGroupComponent } from './ui/controls/checkbox-group/checkbox-group.component';
import { RadioGroupComponent } from './ui/controls/radio-group/radio-group.component';
import { CheckboxSingleComponent } from './ui/controls/checkbox-single/checkbox-single.component';
import { FormTitleComponent } from './ui/controls/form-title/form-title.component';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextareaComponent } from './ui/controls/textarea/textarea.component';
import { FileUploadComponent } from './ui/controls/file-upload/file-upload.component';
import { StepLinkComponent } from './ui/controls/step-link/step-link.component';
import { SwitchComponent } from './ui/controls/switch/switch.component';
import { SelectComponent } from './ui/controls/select/select.component';
import { ControlsComponent } from './ui/controls.component';
import { ControlsGroupComponent } from './ui/controls/controls-group/controls-group.component';
import { ErrorFieldComponent } from './ui/controls/error-field/error-field.component';
import { RichTextComponent } from './ui/controls/rich-text/rich-text.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ControlComponent } from './ui/controls/control.component';
import { GlobalConfig } from './models/global.config';
import { FOR_ROOT_CONFIG_TOKEN } from './forms.config';
import { ControlFactoryComponent } from './ui/controls/control-factory.component';
import { SubmitButtonComponent } from './ui/submit-button/submit-button.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputComponent } from './ui/controls/input-control/input-control.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormComponent } from './ui/form/form.component';

@NgModule({
  declarations: [
    AttributesDirective,
    PhoneNumberComponent,
    ValidationMessageComponent,
    InputComponent,
    InfoFieldComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    TextareaComponent,
    CheckboxSingleComponent,
    FormTitleComponent,
    FileUploadComponent,
    StepLinkComponent,
    SwitchComponent,
    SelectComponent,
    ControlsComponent,
    ControlsGroupComponent,
    ErrorFieldComponent,
    RichTextComponent,
    SafeHtmlPipe,
    ControlComponent,
    ControlFactoryComponent,
    SubmitButtonComponent,
    FormComponent,
  ],
  exports: [
    AttributesDirective,
    PhoneNumberComponent,
    ValidationMessageComponent,
    InputComponent,
    InfoFieldComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    CheckboxSingleComponent,
    FormTitleComponent,
    TextareaComponent,
    FileUploadComponent,
    StepLinkComponent,
    SwitchComponent,
    SelectComponent,
    ControlsComponent,
    ControlsGroupComponent,
    ErrorFieldComponent,
    RichTextComponent,
    SafeHtmlPipe,
    ControlComponent,
    ControlFactoryComponent,
    SubmitButtonComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    InputNumberModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
  ],
  providers: [
    MaskPipe,
    {
      provide: FOR_ROOT_CONFIG_TOKEN,
      useValue: null,
    },
  ],
})
export class DlFormsModule {
  static forRoot(config: GlobalConfig): ModuleWithProviders<DlFormsModule> {
    return {
      ngModule: DlFormsModule,
      providers: [
        {
          provide: FOR_ROOT_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
