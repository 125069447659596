import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchCriteria } from '../modules/crud/models/search-criteria.model';
import { AbstractCRUDService } from '../modules/crud/services/abstract-crud.service';
import { MessageListResponse } from './models/message-list-repsponse';
import { Message, MessageDTO } from './models/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends AbstractCRUDService<Message, MessageDTO> {
  override model = Message;
  override path = '/messages';

  override getMyAll(
    searchCriteria?: SearchCriteria | undefined,
    options?: { headers: HttpHeaders } | undefined
  ): Observable<MessageListResponse> {
    const url = this.getApiHost() + this.path + '/me/list';
    return this.httpClient.post<MessageListResponse>(url, searchCriteria, options).pipe(
      map((result: MessageListResponse) => {
        const response = new MessageListResponse().loadModel(result);
        response.records = result.records.map(this.loadModel);
        return response;
      })
    );
  }

  markAsRead(messages: Array<string>): Observable<MessageListResponse> {
    const url = this.getApiHost() + this.path + '/mark-as-read';

    return this.httpClient.post<MessageListResponse>(url, messages).pipe(
      map((result: MessageListResponse) => {
        console.log('result: ', result);
        const response = new MessageListResponse().loadModel(result);
        response.records = result.records.map(this.loadModel);
        return response;
      })
    );
  }
}
