import { User } from './../../user/models/user';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { PermissionsService } from '../permissions.service';
import { Permissions } from '../models/permissions.model';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionsService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = localStorage.getItem('user');
    const loggedIn = !!user;

    if (!loggedIn) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    const userLoaded = new User().loadModel(JSON.parse(user).user);
    const permissions = new Permissions();
    permissions.permissions = userLoaded.permissions;
    permissions.roles = userLoaded.roles;

    this.permissionService.setPermissions(permissions);

    return true;
  }
}
