import { RoleEnum } from './../enums/role.enum';
export class Permissions {
  roles: Array<string> = [];
  permissions: Array<string> = [];

  checkPermission(permissions: Array<string>) {
    const isAdmin = this.roles.find((role) => role === RoleEnum.Admin);

    if (isAdmin) {
      return true;
    }

    const hasIt = permissions.some((permission) => {
      const hasRole = this.roles.some((role) => role === permission);

      const hasPermission = this.permissions.some((role) => role === permission);
      return hasRole || hasPermission;
    });

    return hasIt;
  }
}
