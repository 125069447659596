<ng-container>
  <div *ngIf="root && item.visible !== false && checkPermissions(item.permissions)" class="layout-menuitem-root-text">
    {{ item.label }}
  </div>
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    *ngIf="(!item.routerLink || item.items) && item.visible !== false && checkPermissions(item.permissions)"
    (mouseenter)="onMouseEnter()"
    (keydown.enter)="itemClick($event)"
    [ngClass]="item.class"
    pRipple
    [attr.target]="item.target"
    [attr.tabindex]="0"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i class="pi pi-fw layout-submenu-toggler" [ngClass]="'pi-angle-down'" *ngIf="item.items"></i>
  </a>
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    *ngIf="item.routerLink && !item.items && item.visible !== false && checkPermissions(item.permissions)"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [ngClass]="item.class"
    pRipple
    [routerLinkActiveOptions]="{ exact: !item.preventExact }"
    [attr.target]="item.target"
    [attr.tabindex]="0"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i class="pi pi-fw layout-submenu-toggler" [ngClass]="'pi-angle-down'" *ngIf="item.items"></i>
  </a>

  <ul
    *ngIf="item.items && item.visible !== false"
    role="menu"
    [@children]="
      root
        ? isMobile()
          ? 'visible'
          : slimClick
          ? active
            ? 'slimVisibleAnimated'
            : 'slimHiddenAnimated'
          : active
          ? 'visible'
          : 'hidden'
          ? active
            ? 'visibleAnimated'
            : 'hiddenAnimated'
          : active
          ? 'visible'
          : 'hidden'
        : root
        ? 'visible'
        : active
        ? 'visibleAnimated'
        : 'hiddenAnimated'
    "
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li app-menu-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    </ng-template>
  </ul>
</ng-container>
